<template>
  <div class="corpModel" style="margin-left: 3vw;">
    <div class="operationButton">
      <el-button size="small" @click="clearList">取消 </el-button>
      <el-button v-if="!this.eaitState" type="primary" class="butColor" @click="saveModel(processInfo, titleForm)"
        size="small">保存
      </el-button>
    </div>
    <div class="conter">
       <div class="proctImg essentialImg">
        <el-upload v-if="!eaitState" class="avatar-uploader" action="/imgUpdata" :show-file-list="false"
          :on-success="plantImghandleSuccess" :on-error="plantImghandleError" :before-upload="beforeAvatarUpload"
          :headers="{token}">
          <img v-if="plantImg" :src="plantImg" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <img v-else-if="eaitState" :src="plantImg" class="avatar" />
      </div>
      <div class="essential">
        <div>
          <h3>基本信息</h3>
          <div v-if="$attrs['nonBatch'] == 'cropsNew'" class="public titleFormCon">
            <el-form ref="titleForm" :rules="rules" class="batchBox" :model="titleForm" label-width="80px">
              <el-form-item label="作物名称" prop="cropId">
                <el-select v-model="titleForm.cropId" @change="cropNameChange" placeholder="请选择作物名称">
                  <el-option v-for="item in cropNewList" :key="item.id" :label="item.cropName"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div>
          <div class="grow">
            <h3>生长周期</h3>
          </div>
          <div class="information">
            <div v-for="item in processInfo" :key="item.id">
              <div class="gowthStageItemBox">
                <div class="gowthStageItem" v-for="(self, index) in item.lifeCyclesModelInfoList" :key="self.id">
                  <button type="button" class="buttons" aria-label="Close" @click="close(item, index)">
                    <i class="el-dialog__close el-icon el-icon-close"></i>
                  </button>
<!--                  <div class="imgUpload">
                    <el-upload v-if="!eaitState" class="avatar-uploader" action="/imgUpdata" :show-file-list="false"
                      :on-success="(file) => handleSuccess(file, self)" :on-error="plantImghandleError"
                      :before-upload="beforeAvatarUpload" :headers="{token}">
                      <img v-if="self.imgShow" :src="self.imgName" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <img v-else-if="eaitState" :src="self.imgName" class="avatar" />
                  </div>-->
                  <div>
                    <el-form class="phase" ref="phaseDetails" :model="phaseDetails">
                      <el-form-item>
                        <el-input v-model="self.name" :readonly="eaitState" placeholder="请输入作物周期"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input v-model="self.day" :readonly="eaitState" placeholder="请输入"><i slot="suffix" style="
                              font-style: normal;
                              margin-right: 30px;
                              color: black;
                            ">天</i></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input :readonly="eaitState" v-model="self.rempMin" placeholder="最小值"><i slot="suffix" style="
                              font-style: normal;
                              margin-right: 30px;
                              color: black;
                            ">℃</i></el-input>
                        <!-- <div class="gang"></div> -->
                        <el-input :readonly="eaitState" v-model="self.rempMax" placeholder="最大值"><i slot="suffix" style="
                              font-style: normal;
                              margin-right: 30px;
                              color: black;
                            ">℃</i></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
                <!-- </div> -->
                <!--  -->
                <div class="addGowthStage">

                  <span @click="addModel(item)" class="el-icon-plus"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { batch } from "@/utils/required.js";
export default {
  data() {
    return {
      token: '',
      titleForm: {
        cropId:'',
        cropName: '',
        cropType: '',
        cropImg: '',
      },
      processInfo: [
        {
          id: 0,
          name: "",
          lifeCyclesModelInfoList: [
            {
              name: "",
              imgName: "",
              day: "",
              url: "",
              rempMin: "",
              rempMax: "",
            },
          ],
        },
      ],

      plantImg: null,
      phaseDetails: {},
      eaitState: false,
      address: null,
      addAndEait: false,
      rules: batch,
      cropNewList: [],
    };
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem('token'));
    this.getCropList();
    this.$nextTick(() => {
      this.$refs.titleForm.resetFields();
      setTimeout(() => {
        if (this.$attrs["echoStatus"]) {
          this.titleForm.plotName = this.$attrs["batchWkt"].name;
          this.titleForm.plantingArea = this.$attrs["batchWkt"].size;
          this.$forceUpdate();
          this.titleForm.plotId = this.$attrs["batchWkt"].id;
          this.titleForm.massifId = this.$attrs["batchWkt"].id;
        }
      }, 1000);
    });
  },

  methods: {



    // 获取作物列表
    getCropList() {
      this.$get("/cropManagement/getCropManagements", {
        page: 1,
        size: 10000,
        // cropName: '',
      }).then((res) => {
        if (res.data.state == "success") {
          this.cropNewList = res.data.datas;
        }
      });
    },

    // 生长周期关闭弹框
    close(item, index) {
      //console.log(item, index);
      item.productCycleModelInfoBeanList.splice(index, 1);
    },

    // 添加新的生长周期
    addModel(item) {
      if (this.eaitState) return;
      item.lifeCyclesModelInfoList.push({
        name: "",
        day: "",
        url: "",
        rempMin: "",
        rempMax: "",
      });
    },

    addGrowthStage() {
      if (this.eaitState) return;
      this.processInfo.push({
        id: this.processInfo.length + 1,
        name: "生育期",
        productCycleModelInfoBeanList: [
          {
            name: "",
            day: "",
            url: "",
            rempMin: "",
            rempMax: "",
          },
        ],
      });
    },

    // 图片上传
    handleSuccess(file, self) {
      //console.log(file, self);
      this.$getBlob("/fileops/show", {
        fileName: file.data,
      }).then((res) => {
        //console.log(res,"图片");
        let fileNames = res.config.params.fileName;
        let fileData = fileNames.split(".")[1];
        //console.log(fileData);
          let blob = new Blob([res.data],{type: 'image/'+ fileData})
        let url = window.URL.createObjectURL(blob);
        self.imgName = url;
        self.url = file.data;
        this.processInfo = JSON.parse(JSON.stringify(this.processInfo));
        //console.log(self.url)
      })
    },
    plantImghandleSuccess(file) {
      this.$getBlob("/fileops/show", {
        fileName: file.data,
      }).then((res) => {
        let fileNames = res.config.params.fileName;
        let fileData = fileNames.split(".")[1];
          let blob = new Blob([res.data],{type: 'image/'+ fileData})
        let url = window.URL.createObjectURL(blob);
        this.plantImg = "http://public.half-half.cn/" +fileNames;
        //console.log(this.plantImg)
      })
    },
    plantImghandleError() {
      this.$message.info("上传失败");
    },
    beforeAvatarUpload(val) {
      const fileLimitSize = 5;// 几兆限制
      const isJPG = val.type === "image/jpeg";
      const isJPGs = val.type === "image/png";
      const fileLimit = val.size / 1024 / 1024 < fileLimitSize;
      if (!isJPG && !isJPGs) {
        this.$message.info("上传图片格式只能为 JPG 或 PNG 格式！");
        this.fileList = [];
        return false
      }
      if (!fileLimit) {
        this.$message.info(`上传图片大小不能超过${fileLimitSize}MB!`);
        this.fileList = [];
        return false
      }

      return true;
    },

    /**
     * @description: 保存
     * @param {*} data
     * @param {*} nonBatch true 生产周期配置   fasle 种植批次
     */
    saveModel(data, base) {
      // if (!this.plantImg) this.$message.info("请上传图片");
      this.titleForm.cropImg = this.plantImg
      //console.log(data);
      //console.log(base);
      let batch = {
        processInfo: JSON.stringify(data),
              ...base,
            };
            //console.log(batch);
      if(base.id){
        this.$emit("saveModel", batch, "eait");
      }else{
        this.$emit("saveModel", batch, "add");
      }
      
    },

    // exitDoig() {
    //   this.$emit('exits');
    // }
    /**
     * @description: 生产周期配置编辑
     * @param {*} val 值
     * @param {*} state 编辑1 查看0
     */
    taitModel(val, state) {
      //console.log("====chuanzhi======")
      //console.log(val, state);
      
      this.$nextTick(() => {
        let {
          cropImg,
          cropId,
          cropName,
          cropType,
          lifeCyclesModelList,
          id,
        } = val;
        this.plantImg = cropImg;
        this.titleForm = {
          cropName,
          cropType,
          cropId,
          id,
        };
        
        this.processInfo = JSON.parse(JSON.stringify(lifeCyclesModelList));
        if (lifeCyclesModelList[0]== 0 ) {
          this.processInfo[0].lifeCyclesModelInfoList = [
            {
              name: "",
              day: "",
              url: "",
              rempMin: "",
              rempMax: "",
            },
          ];
        }

        if (this.processInfo[0]) {
          let lifeCycles = this.processInfo[0].lifeCyclesModelInfoList;
          lifeCycles.forEach((a, A) => {
            this.$getBlob("/fileops/show", {
              fileName: a.url,
            }).then((res) => {
              let fileNames = res.config.params.fileName;
              let fileData = fileNames.split(".")[1];
              // //console.log(fileData);
              let blob = new Blob([res.data],{type: 'image/'+ fileData})
              let url = window.URL.createObjectURL(blob);
              // //console.log(url);
              lifeCycles[A].imgName = url;
              // lifeCycles[A].imgShow = true;
              this.$set(lifeCycles[A], 'imgShow', true);
              //console.log(this.processInfo[0]);
            })
          })
        }

        //console.log(this.processInfo[0].lifeCyclesModelInfoList);
        this.address = "eait";
        if (state == 0) this.eaitState = true;

      });
    },
    /**
     * @description:
     * @param {*}id
     * 作物切换
     */
    cropNameChange(id) {
      //console.log(id);
      for (let i = 0; i < this.cropNewList.length; i++) {
        if (this.cropNewList[i].id == id) {
          this.titleForm.cropName = this.cropNewList[i].cropName;
          this.titleForm.cropType = this.cropNewList[i].cropType;
        }
      }
    },
    /**
     * @description:  编辑回显
     * @param {*}
     */
    echoPlantingBatch() {
      //console.log(this.$attrs["productCycle"]);
      this.addAndEait = true;
      // this.titleForm = {};
      let datas = this.$attrs["productCycle"].productCycle
        .productCycleModelBeanList;
      //console.log(this.$attrs["productCycle"]);
      let plantingBatchBean = this.$attrs["productCycle"].plantingBatchBean;
      //console.log(plantingBatchBean);
      if (datas.length == 0) return;
      this.processInfo = datas;
      this.processInfo.plantType = plantingBatchBean.plantType;
      this.titleForm = plantingBatchBean;
      this.titleForm.productName = plantingBatchBean.plantName;
      //console.log(this.titleForm.productName);
      this.plantImg = this.$attrs["productCycle"].productCycle.plantImg;
      let cameraIds = this.$attrs["productCycle"].cameraIds;
      //console.log(cameraIds);
      let deviceIds = this.$attrs["productCycle"].deviceIds;
      //console.log(deviceIds);
      this.titleForm.cameraIdsFrom = this.$attrs["productCycle"].cameraName;
      this.titleForm.deviceIdsFrom = this.$attrs["productCycle"].deviceName;
    },
    // 取消
    clearList() {
      this.$emit("exits", this.addAndEait);
      this.$nextTick(() => {
        this.$refs["titleForm"].resetFields();
        this.plantImg = "";
        this.processInfo[0].lifeCyclesModelInfoList = [
          {
            name: "",
            day: "",
            url: "",
            rempMin: "",
            rempMax: "",
          },
        ];
      });
    },

    },

    
};
</script>

<style scoped lang="less" src="../../style/public.less"></style>

<style lang="less" scoped>
@import "../../style/colorPublic";

.operationButton {
  text-align: right;
  position: absolute;
  top: 2vh;
  right: 20px;
  width: 30%;
}

.butColor {
  background: @but_color;
}

.conter {
  margin-top: 2vh;
  display: flex;

  .essentialImg {
    width: 20vh;
    border: 1px solid #ccc;
    height: 20vh;
    margin-right: 3vh;
    border-radius: 5px;
  }
}

.essential {
  flex: 1;
  width: 30vw;

  //   border: 1px solid green;
  h3 {
    color: black;
    font-size: 2.1vh;
    font-weight: 500;
    margin: 1vh 0;
  }
}

.titleFormCon {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
}

.titleForm {
  display: flex;
  position: relative;
}

.batchBox {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.grow {
  display: flex;
  justify-content: space-between;
}

.written {
  border: 0;
  outline: 0;
}

.growBut {
  height: 3vh;
}

// 流程信息
.gowthStageItemBox {
  margin: 1vh 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 35vw;
  //   max-height: 60vh;
  overflow: hidden;
  height: 30vh;
  overflow-y: auto;
}

.gowthStageItem {
  position: relative;
  border: 1px solid #ccc;
  width: 20vh;
  height: 37vh;
  margin: 0 2vh 2vh 0;
}

.imgUpload {
  width: 15vh;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  margin: 2vh auto 1vh auto;
}

.imgUpload .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.imgUpload .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.imgUpload .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 15vh;
  line-height: 15vh;
  text-align: center;
}

.imgUpload .avatar {
  max-width: 15vh;
  max-height: 15vh;
  display: block;
}

.phase /deep/ .el-input__inner {
  width: 15vh;
  height: 4vh !important;
  line-height: 3vh !important;
}

.phase /deep/ .el-form-item {
  text-align: center;
  margin-bottom: 0;
}

.phase /deep/ .el-button--default {
  height: 3vh !important;
  padding: 9px 19px;
}

.addGowthStage {
  margin: 0 2vh;

  span {
    display: block;
    cursor: pointer;
    font-size: 6vh;
  }
}

.information {
  // height: 50vh;
  overflow: hidden;
  overflow-y: auto;
}

.proctImg {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.proctImg .avatar {
  max-width: 20vh;
  max-height: 20vh;
  display: block;
}

.proctImg .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 20vh;
  line-height: 20vh;
  text-align: center;
}

.plantingClassInput /deep/ .el-date-editor {
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .growBut {
    height: 4vh;
  }

  .phase /deep/ .el-form-item__content {
    line-height: 27px;
  }

  .phase /deep/ .el-form-item {
    margin-bottom: 0 !important;
    text-align: center;
  }

  .gowthStageItemBox {
    height: 43vh;
  }
}

.public /deep/ .el-form-item {
  margin-bottom: 2vh !important;
}

.gang {
  width: 1vw;
  height: 2px;
  margin-left: 0.2vw;
  margin-right: 0.2vw;
  background-color: #ccc;
  display: inline-block;
}

.buttons {
  position: absolute;
  top: 10px;
  right: 5px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

/deep/ input::-webkit-input-placeholder {
  font-size: 1.5vh;
}
</style>
