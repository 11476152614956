<template>
  <div class="productionCycle">
    <div class="title">
      <div class="search">
        <el-button class="butColor" @click="addClick" type="primary" size="small">新建</el-button>
        <el-form ref="searchForm" class="searchForm" :model="searchForm" label-width="80px">
          <el-form-item label="关键字">
            <el-input v-model="searchForm.cropNames" placeholder="请输入产品名称"></el-input>
            <el-button @click="searchBut(searchForm.cropNames)" class="searchBut" icon="el-icon-search"></el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--  -->
    <div class="conter" v-if="cycleList.length != 0">
      <div class="listItem" v-for="item in cycleList" :key="item.id">
         <img :src="item.cropImg" alt="" />
        <span>名称：{{ item.cropName }}</span>
        <span>类别：{{ item.cropType }}</span>
        <div class="operation">
          <el-button @click="lookModel(item)" size="small">查看</el-button>
          <el-button @click="eaitModel(item)" size="small">编辑</el-button>
        </div>
        <i class="delectIcon el-icon-delete-solid" @click="delectProcct(item.id)"></i>
      </div>
    </div>
    <div style="text-align: center;color: #909399; font-size: 1.5vh;" v-else>暂无数据</div>
    <div class="pageBox">
      <el-pagination layout="total, sizes, prev, pager, next, jumper" :total="dataTotal" :current-page="pageNow"
                     :page-sizes="[8, 10, 20, 50]" :page-size="pageSize" @size-change="siceChange" @current-change="currentChange">
      </el-pagination>
    </div>
    <!-- New crop -->
    <el-dialog destroy-on-close :title="titleName" class="addCrop" :visible.sync="newCrop" width="50%">
      <crop-model :nonBatch="nonBatch" ref="model" @exits="exits" @saveModel="saveModel" />
    </el-dialog>
  </div>
</template>
<script>
import cropModel from '@/components/soureUnit/cropBatch2.vue';
export default {
  components: { cropModel },
  data() {
    return {
      typeList: [],
      searchForm: {},
      newCrop: false,
      cycleList: [],
      nonBatch: 'cropsNew',
      titleName: '',
      dataTotal: 0,
      // 当前页
      pageNow: 1,
      // 当前页数
      pageSize: 10,
      page: 1,
      size: 10,
    };
  },
  mounted() {
    this.requestcycle();
    // this.requestTypeAll();
  },
  methods: {
    /**
     * @description:  所有类别搜索
     */

    requestcycle(plantName, plantType) {
      this.$get('/lifeCycles/getLifeCycleList', { page: this.pageNow, size: this.pageSize, cropName: plantName || '', cropType: plantType || '' }).then((res) => {
        if (res.data.state == 'success') {
          this.cycleList = res.data.datas;
          this.dataTotal = res.data.count;
        } else {
          this.cycleList = [];
        }
      });
    },

// 序号
    indexMethod(index) {
      return index + this.pageSize * (this.pageNow - 1) + 1;
    },
    // 当前页数
    siceChange(val) {
      this.pageSize = val;
      this.requestcycle();
    },
    // 当前页
    currentChange(val) {
      this.pageNow = val;
      this.requestcycle();
    },
    addClick() {
      this.newCrop = true;
      this.$refs.model.eaitState = false;
      this.$refs.model.address = 'add';
      this.titleName = '添加作物'
    },
    searchBut(val) {
      this.pagination.page = 1;
      this.pagination.size = 8;
      this.requestcycle(val);
      this.searchForm.region = '';
    },
    exits() {
      this.newCrop = false;
    },
    saveModel(data, address) {
      //console.log(data, address);
      let url = '/lifeCycles/addLifeCycle';
      if (address == 'eait') {
        url = '/lifeCycles/updateLifeCycle';
      }

      this.$post(url, data).then((res) => {
        if (res.data.state == 'success') {
          this.$message.success('操作成功');
          this.newCrop = false;
          this.requestcycle();
        }
      });
    },
    /**
     * @description: 删除
     * @param {*} id
     */

    delectProcct(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$delete(`/lifeCycles/delLifeCycle/${id}`).then((res) => {
            if (res.data.state == 'success') {
              this.$message.success('删除成功');
              this.requestcycle();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    /**
     * @description:
     * @param {*} val
     */

    eaitModel(val) {
      this.newCrop = true;
      this.titleName = '编辑作物'
      this.$nextTick(() => {
        this.$refs.model.eaitState = false;
        this.$refs.model.taitModel(val, 1);
      });
    },
    /**
     * @description: 查看
     * @param {*}val
     */

    lookModel(val) {
      //console.log(val);
      this.newCrop = true;
      this.titleName = '查看作物'
      this.$nextTick(() => {
        this.$refs.model.taitModel(val, 0);
        this.$refs.model.eaitState = true;
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/style/colorPublic";
.butColor{
    // background: @but_color;
    background: #409eff;
}
.pageBox {
  position: fixed;
  right: 5vh;
}
.search {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
}
.searchForm {
  display: flex;
  position: relative;
}
.searchForm /deep/ .el-form-item {
  margin-bottom: 0;
}
.searchBut {
  position: absolute;
  right: 0;
  top: 0px;
}

.conter {
  display: flex;
  padding: 1vh 0 2vh 0;
  box-sizing: border-box;
  flex-wrap: wrap;
  height: 76vh;
  overflow: hidden;
  overflow-y: auto;
  align-items: baseline;
}
.listItem {
  width: 19vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ccc;
  align-items: center;
  padding-top: 1vh;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 2vh 2vh 0;
  position: relative;
  img {
    width: 15vh;
    height: 10vh;
    border: 1px solid #ccc;
    margin-bottom: 1vh;
  }
  .delectIcon {
    position: absolute;
    right: 1vh;
    top: 1vh;
    cursor: pointer;
  }
  span {
    border: 1px solid blue;
    font-size: 1.5vh;
    margin-bottom: 0.5vh;
    padding: 0.5vh;
    box-sizing: border-box;
    width: 60%;
    text-align: center;
    border-radius: 10px;
  }
  .operation {
    margin-top: 1vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    button {
      width: 50%;
    }
    button:nth-child(1) {
      border-bottom-left-radius: 10px;
    }
    button:nth-child(2) {
      border-bottom-right-radius: 10px;
    }
  }
}
.addCrop /deep/ .el-dialog {
  height: 78vh;
  overflow: hidden;
}
@media screen and (max-width: 1500px) {
  .addCrop {
    height: 98vh;
    overflow: hidden;
  }
  .addCrop /deep/ .el-dialog {
    height: 93vh;
    overflow: hidden;
  }
  .searchForm /deep/ .el-input__inner {
    height: 5vh !important;
    line-height: 5vh !important;
  }
  .searchForm /deep/ .el-button--default {
    height: 5vh !important;
    padding: 9px 19px;
  }
  .searchBut {
    position: absolute;
    right: 0;
    top: 4px;
  }
}
.productionCycle {
  background: #fff;
  padding: 2vh;
  box-sizing: border-box;
}
.search {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
  // border: 1px solid #ccc;
  padding: 1vh;
  box-sizing: border-box;
}
.searchForm {
  display: flex;
  position: relative;
}
.searchForm /deep/ .el-form-item {
  margin-bottom: 0;
}
.searchBut {
  position: absolute;
  right: 0;
  top: 0;
}
/* .searchForm /deep/ .el-input__inner {
  height: 5vh !important;
  line-height: 5vh !important;
}
.searchForm /deep/ .el-button--default {
  height: 5vh !important;
  padding: 9px 19px;
} */
/deep/ .gowthStageItemBox{
  height: 38vh;
}
@media screen and (max-width: 1500px) {
  .searchForm /deep/ .el-input__inner {
    height: 5vh !important;
    line-height: 5vh !important;
  }
  .searchForm /deep/ .el-button--default {
    height: 5vh !important;
    padding: 9px 19px;
  }
  .searchBut {
    position: absolute;
    right: 0;
    top: 4px;
  }
  .conter {
    height: 70vh;
  }
}
</style>
